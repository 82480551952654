import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/default.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2><strong parentName="h2">{`Seu cadastro foi realizado com sucesso!`}</strong></h2>
    <p>{`Assim que uma vaga estiver disponível você receberá um email para concluir a inscrição e iniciar a avaliação.`}</p>
    <p>{`Desejamos a ti, muito sucesso.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      